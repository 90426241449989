/* width */
[data-testid="slide-roll"] ::-webkit-scrollbar {
    width: 10px;
  }
  
  /* Track */
[data-testid="slide-roll"] ::-webkit-scrollbar-track {
    background: #2C2C2C;
}
  
  /* Handle */
[data-testid="slide-roll"]  ::-webkit-scrollbar-thumb {
    background: #2C2C2C;
}
  
  /* Handle on hover */
[data-testid="slide-roll"] ::-webkit-scrollbar-thumb:hover {
    background: #2C2C2C;
}